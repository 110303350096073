/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    object, shape, string, arrayOf,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ReactMarkdown from 'react-markdown';

import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import SeoLeftRailBlock from './Partials/SeoLeftRailBlock';
import SeoRelatedArticlesBlock from './Partials/SeoRelatedArticlesBlock';
import GraphqlBreadcrumbs from '../GraphqlCommonComponents/GraphqlBreadcrumbs/GraphqlBreadcrumbs';
import customBreakpoints from '../../../helpers/customBreakpoints';
import { trackData } from '../../../helpers/tracking/common/commonTrackingHelpers';
import SeoBirthFlowersBlock from './SeoBirthFlowersBlock/SeoBirthFlowersBlock';
import GraphqlSeoHeadTags from '../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import SeoUniversalPicturesInfoBlock from './SeoUniversalPicturesInfoBlock/SeoUniversalPicturesInfoBlock';
import KlarnaOnSiteMessgeScript from '../../Checkout/KlarnaPaymentOnSiteMessage/KlarnaOnSiteMessageScript/KlarnaOnSiteMessgeScript';
import { getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';
import { getPresentationFamily, getFeatureFlags, getCurrentBrandId } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import SmartGiftBlock from '../SmartGiftPage/SmartGiftBlock';
import { getOperatingSystem } from '../../../../state/ducks/App/ducks/Config/Config-Helpers';
import { RenderChatAsLink } from '../../MultiBrandChatController/AigoChatWidgetWrapperV2/popOpenChat';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            padding: '15px',
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
            padding: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '100%',
            padding: '15px',
        },
    },
    articleBodyLeftRail: {
        fontSize: 14,
        '& strong': {
            display: 'block',
        },
        '& a': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    },
    articleBody: {
        fontSize: 14,
        '& p': {
            paddingLeft: 40,
            '& img': {
                width: 'auto',
            },
        },
        '& ul': {
            marginLeft: 32,
        },
    },
    header: {
        margin: '0px 0px 10px',
        fontWeight: 'normal',
        textAlign: 'center',
    },
    headerLeftRail: {
        margin: '10px 0px 10px',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    heroImg: {
        width: '100%',
    },
    breadcrumb: {
        marginBottom: 10,
        paddingLeft: 30,
    },
    alignment: {
        display: 'flex',
    },
    heroBlock: {
        textAlign: 'center',
    },
    heroTitle: {
        margin: '0',
        color: theme.palette.flowerGift.topLogoText.color,
        fontFamily: 'Roboto Slab serif',
        fontSize: '40px',
        fontWeight: '300',
        border: 'none',
        lineHeight: '1.2em',
    },
    heroCopy: {
        fontSize: '1.6em',
        fontWeight: 'bold',
        '& p': {
            marginTop: '10px',
        },
    },
    imageBlock: {
        width: '100%',
    },
    contentWrapper: {
        display: 'flex',
    },
    aboutUsContainer: {
        maxWidth: '1440px',
        background: theme.palette.backgroundColor,
        margin: '0 auto',
        [theme.breakpoints.down('600')]: {
            maxWidth: '100%',
        },
        '& $contentWrapper': {
            textAlign: 'center',
            justifyContent: 'center',
        },
        '& $imageBlock': {
            width: '75%',
            margin: '0 auto',
        },
        '& img': {
            maxWidth: '100%',
            width: '35%',
            objectFit: 'contain',
        },
        '& p': {
            whiteSpace: 'break-spaces',
        },
        '& $header': {
            fontSize: '35px',
            fontWeight: 'blod',
        },
    },
    appleChatContainer: {
        maxWidth: '1440px',
        margin: '0 auto',
        [theme.breakpoints.down('600')]: {
            maxWidth: '100%',
        },
        '& $imageBlock, & p': {
            lineHeight: 0,
            margin: 0,
        },
        '& $header, & $breadcrumb': {
            display: 'none',
        },
        '& img': {
            maxWidth: '100%',
            width: '100%',
        },
    },
    passportContainer: {
        maxWidth: '1440px',
        margin: '0 auto',
        '& $heroTitle': {
            width: '50%',
            fontSize: '20px',
            margin: '0 auto',
            fontWeight: 700,
            [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
                width: '100%',
            },
        },
        [theme.breakpoints.down('600')]: {
            maxWidth: '100%',
        },
        '& $heroBlock, & $articleBody': {
            width: '70%',
            margin: '0 auto',
            [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
                width: '95%',
            },
        },
        '& $articleBody': {
            '& p': {
                textAlign: 'left ',
                paddingLeft: 0,
            },
        },
        '& ol': {
            paddingLeft: '20px',
        },
        '& h2': {
            textAlign: 'center',
            fontSize: '18px',
        },
        '& $heroCopy': {
            fontSize: '12px',
            '& p': {
                textAlign: 'right',
            },
        },
    },
    flowersUSA: {
        '& $header': {
            color: theme.palette.primary.main,
            fontWeight: 200,
            textAlign: 'left',
            maxWidth: '66%',
            fontSize: 40,
            [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
                maxWidth: '100%',
            },
        },
        '& $contentWrapper': {
            backgroundColor: '#f3faf6',
            maxWidth: '1000px',
            margin: 'auto',
            padding: '25px',
            backgroundImage: 'url(https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/bltafdd6753c89bbd83/5ef504871ce00b649d2b557c/bkgd.jpg)',
            backgroundPosition: 'top 100px right 25px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '60%',
            [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
                padding: '10px',
                backgroundImage: 'none',
            },
        },
        '& $articleBody': {
            display: 'flex',
            flexWrap: 'wrap',
            '& p': {
                width: '50%',
                margin: '0 0 25px 0',
                padding: '0 15px 15px 15px',
                border: `1px solid ${theme.palette.grey10}`,
                borderTop: 0,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                textAlign: 'left',
                [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
                    width: '100%',
                },
                '& a': {
                    width: '33%',
                    margin: '8px 0',
                    fontSize: 16,
                    color: theme.palette.black,
                    [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
                        width: '50%',
                    },
                },
                '& strong': {
                    width: '100%',
                    fontSize: 16,
                    [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
                        fontSize: 20,
                        margin: '10px 0',
                    },
                },
            },
            '& p:nth-of-type(1)': {
                width: '45%',
                fontSize: 20,
                border: 0,
                lineHeight: '30px',
                padding: 0,
                marginRight: '50%',
                [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
                    width: '100%',
                    marginRight: 0,
                },
            },
            '& p:nth-of-type(2)': {
                width: '51%',
                [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
                    width: '100%',
                },
            },
            '& p:nth-of-type(3), p:nth-of-type(4), p:nth-of-type(5)': {
                flex: '33.3%',
                padding: '0 10px',
                minHeight: '500px',
                [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
                    flex: '100%',
                    minHeight: 'auto',
                },
                '& a': {
                    width: '50%',
                    [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
                        width: '33%',
                    },
                    [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
                        width: '50%',
                    },
                },
            },
            '& p:nth-of-type(4), p:nth-of-type(5)': {
                borderLeft: 0,
                [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
                    borderLeft: `1px solid ${theme.palette.grey10}`,
                    borderTop: `1px solid ${theme.palette.grey10}`,
                },
            },
            '& h2:nth-of-type(2)': {
                width: '100%',
                paddingBottom: '20px',
                [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
                    border: 0,
                    borderBottom: `1px solid ${theme.palette.grey10}`,
                },
            },
            '& h2': {
                width: '51%',
                fontSize: 24,
                margin: 0,
                padding: 15,
                border: `1px solid ${theme.palette.grey10}`,
                borderBottom: 0,
                [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
                    width: '100%',
                },
            },
        },
    },
    termsAndPrivacy: {
        maxWidth: 980,
        margin: 'auto',
        '& $articleBody': {
            '& p': {
                textAlign: 'left',
                paddingLeft: 0,
            },
            '& img': {
                width: 'auto',
                position: 'absolute',
                top: 0,
                right: 0,
            },
        },
        '& $breadcrumb': {
            paddingLeft: 0,
        },
        '& $header': {
            textAlign: 'left',
        },
        '& $contentWrapper': {
            position: 'relative',
        },
    },
    succulentArticleBody: {
        '& $articleBody': {
            '& p': {
                textAlign: 'left',
                paddingLeft: 0,
            },
        },
    },
    drewBaldridgeArticleBody: {
        '& $articleBody': {
            textAlign: 'center',
            '& p': {
                paddingLeft: 0,
            },
            [theme.breakpoints.down('600')]: {
                '& img': {
                    width: '100%',
                },
            },
        },
        '& $articleBlock': {
            justifyContent: 'center',
            textAlign: 'center',
            '& img': {
                width: 'auto',
            },
        },
    },
    articleBlock: {
        width: '100%',
    },
});

const SeoArticlesBlock = ({ classes, data }) => {
    const ssrDeviceType = useSelector(getSSRDeviceType);
    const presentationFamily = useSelector(getPresentationFamily);
    const operatingSystem = getOperatingSystem().toLowerCase();
    const currentBrand = useSelector((state) => getCurrentBrandId(state));
    const featureFlags = useSelector((state) => getFeatureFlags(state));
    useEffect(() => {
        if (typeof window !== 'undefined' && window?.location?.hash) {
            const hashElement = document.getElementById(`${window.location.hash}`.slice(1));
            if (hashElement) {
                const mobileTopPosition = presentationFamily === 'food' ? 115 : 60;
                const headerOffset = ssrDeviceType === 'mobile' ? mobileTopPosition : 0;
                const targetElementPosition = hashElement.getBoundingClientRect().top;
                const offsetPosition = targetElementPosition + window.pageYOffset - headerOffset;
                window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
            }
        }
    }, []);

    // Persist data layer
    trackData({
        untData: data,
        action: 'track_seo_articles_data',
    });

    const {
        article_header = '', article_body = '', hero: {
            hero_image_desktop, hero_image_mobile, title = '', copy = '',
        } = {}, customer_reviews_gallery = {}, related_articles = {}, left_rail = [], seo = {},
    } = data;
    const isMobile = useMediaQuery('(max-width: 600px)');
    const imageUrl = isMobile && hero_image_mobile ? hero_image_mobile?.url : hero_image_desktop?.url;
    const pageUrl = data?.url;
    if (pageUrl === '/universal-pictures-info') return <SeoUniversalPicturesInfoBlock data={data} />;
    const loadCssUrlBase = () => {
        switch (pageUrl) {
            case '/about-us-company-overview':
                return classes.aboutUsContainer;
            case '/apple-business-chat':
                return classes.appleChatContainer;
            case '/passport-terms-and-conditions':
                return classes.passportContainer;
            case '/flowers-usa':
                return classes.flowersUSA;
            case '/About-Us-Privacy-Policy':
            case '/About-Us-Terms-of-Use':
                return classes.termsAndPrivacy;
            case '/succulent-care':
                return classes.succulentArticleBody;
            case '/drew-baldridge':
                return classes.drewBaldridgeArticleBody;
            default:
                return null;
        }
    };
    if (pageUrl === '/birth-flowers') {
        return <SeoBirthFlowersBlock data={data} />;
    } if (pageUrl === '/smartgift') {
        return <SmartGiftBlock data={data} />;
    }
    return (
        <>
            <GraphqlSeoHeadTags seoData={seo} />
            <KlarnaOnSiteMessgeScript />
            <div className={`${classes.container} ${loadCssUrlBase()}`}>
                <div className={classes.breadcrumb}>
                    <GraphqlBreadcrumbs breadCrumbArray={seo?.breadcrumb} />
                </div>
                <Grid className={classes.alignment}>
                    {left_rail.length > 0 ? <SeoLeftRailBlock data={left_rail} pageUrl={pageUrl} /> : null}
                    <Grid container className={classes.contentWrapper}>
                        <Grid item xs={12} sm={left_rail && left_rail.length === 0 ? 12 : 9} md={left_rail && left_rail.length === 0 ? 12 : 9} lg={left_rail && left_rail.length === 0 ? 12 : 9}>
                            {!title || !copy
                                ? (
                                    <>
                                        <h1 className={classes.header}>{title || article_header}</h1>
                                        {imageUrl && <div className={classes.imageBlock}><img className={classes.heroImg} src={imageUrl} alt={title || 'Hero Image Block'} /></div>}
                                    </>
                                )
                                : (
                                    <>
                                        <Grid className={classes.heroBlock}>
                                            <h1 className={classes.heroTitle}>{title}</h1>
                                            <ReactMarkdown
                                                source={copy}
                                                className={classes.heroCopy}
                                            />
                                        </Grid>
                                        {imageUrl && <img className={classes.heroImg} src={imageUrl} alt={title || 'Hero Image Block'} />}
                                        {article_header && <h1 className={classes.headerLeftRail}>{article_header}</h1>}
                                    </>
                                )}
                            {customer_reviews_gallery?.gallery_id && (
                                <div className="yotpo yotpo-pictures-widget" data-gallery-id={customer_reviews_gallery?.gallery_id} />
                            )}
                            <ReactMarkdown
                                escapeHtml={false}
                                source={article_body}
                                className={left_rail.length === 0 ? classes.articleBody : classes.articleBodyLeftRail}
                                renderers={{
                                    text: ({ value }) => {
                                        if (typeof value === 'string' && value?.toLowerCase()?.includes('chat')) {
                                            return <RenderChatAsLink value={value} operatingSystem={operatingSystem} currentBrand={currentBrand} featureFlags={featureFlags} />;
                                        }
                                        return value;
                                    },
                                }}
                            />
                            {related_articles?.articles.length > 0
                                ? <SeoRelatedArticlesBlock data={related_articles} articleClasses={classes.articleBlock} />
                                : null}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

SeoArticlesBlock.propTypes = {
    classes: object.isRequired,
    data: shape({
        article_header: string.isRequired,
        article_body: string.isRequired,
        hero: shape({
            hero_image_desktop: shape({
                url: string.isRequired,
                filename: string.isRequired,
            }),
        }).isRequired,
        customer_reviews_gallery: shape({
            gallery_id: string,
        }).isRequired,
        related_articles: shape({
            articles: arrayOf(shape({
                image: object.isRequired,
                link: object.isRequired,
            })).isRequired,
            header: string.isRequired,
        }).isRequired,
        left_rail: arrayOf(shape({
            header: string.isRequired,
            link: arrayOf(shape({
                title: string.isRequired,
                href: string.isRequired,
            })).isRequired,
        })).isRequired,
    }).isRequired,
};

export default withStyles(styles)(SeoArticlesBlock);
